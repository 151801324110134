// i18next-extract-mark-ns-start markets-passenger

import { CAPABILITIES_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketPassengerPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Modelling the future of passenger car propulsion</h2>

						<p>
							Passenger cars and light vehicles covers every form of 4-wheel transport from family cars, compact cars and supercars through to SUVs, vans and people carriers.
							They all have the same common goals of fuel economy, sustainability, digitised user customisation, drive performance and comfort – all at an affordable price.
						</p>
						<p>
							However, the traditional petrol and diesel engines in these vehicles have a limited lifespan.
							The clock is ticking and with 2035 set as the target date for zero tailpipe emissions from passenger cars and vans, Original Equipment Manufacturers (OEMs)
							and manufacturers are planning for electrification and/or other forms of propulsion including e-fuels,
							natural gas, hydrogen combustion and hydrogen cells with steps along the way such as hybrid electric vehicles (HEVs) combining combustion engines with electric systems.
						</p>
						<p>
							This is putting a financial strain on already stretched engineering resources who now need to optimise the current IC engine product range to comply with new 2025 Euro 7
							emissions regulations as well as design propulsion systems using a range of innovative technologies.
						</p>
						<p>
							This puts renewed emphasis on the design of power unit and transmission systems. At Realis Simulation we have extensive experience of using software to streamline the vehicle design process –
							answering many of the ‘What ifs’ using computer simulation before OEMs and Tier 1 suppliers commit to expensive physical prototypes.
						</p>
						<p>
							Our software analyses the full vehicle design from fuel intake, power generation, power transfer and exhaust emissions reduction.
							It simulates energy losses from friction, oil consumption and thermal heat transfer as well as component durability across various drive cycles using traditional,
							non-carbon e-fuels, hybrid, electric, hydrogen fuel cells and hydrogen combustion power units.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default MarketPassengerPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-passenger", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/passenger/Realis-Simulation_1373_passengerVehicle2.jpg" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Passenger" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 9
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
